module.exports = {
  siteTitle: "Julien De Marchi - Chef de projet et Développeur web. Présentation détaillée du parcours, des références et des services proposés.", // <title>
  shortSiteTitle: "20 ans d'expérience dans le digital", // <title> ending for posts and pages
  siteDescription: "Plus de 20 années d'expérience dans le digital. Expertise globale de la qualité web (certifié Opquast).",
  siteUrl: "https://www.jdm-communication.com",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "fr",

  /* author */
  authorName: "Julien De Marchi",
  authorTwitterAccount: "jdmcommunication",

  /* info */
  headerTitle: "Julien De Marchi",
  headerSubTitle: "Chef de projet / Concepteur développeur web",

  /* manifest.json */
  manifestName: "Julien De Marchi - Chef de projet et Développeur web",
  manifestShortName: "jdmcommunication", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "twitter", url: "https://twitter.com/juliendemarchi" },
    { name: "facebook", url: "https://www.facebook.com/jdmcommunication" },
    { name: "linkedin", url: "https://www.linkedin.com/in/julien-de-marchi-1378a736/" }
  ]
};
