// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\JDM\\jdm2020-folio\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-category-template-js": () => import("C:\\Users\\JDM\\jdm2020-folio\\src\\templates\\CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-post-template-js": () => import("C:\\Users\\JDM\\jdm2020-folio\\src\\templates\\PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-page-template-js": () => import("C:\\Users\\JDM\\jdm2020-folio\\src\\templates\\PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\JDM\\jdm2020-folio\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("C:\\Users\\JDM\\jdm2020-folio\\src\\pages\\blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-category-js": () => import("C:\\Users\\JDM\\jdm2020-folio\\src\\pages\\category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-contact-js": () => import("C:\\Users\\JDM\\jdm2020-folio\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\JDM\\jdm2020-folio\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

